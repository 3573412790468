.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 1;
}

.sticky {
  background: var(--bg-color);
  border-bottom: 1px solid var(--second-bg-color);
  padding: 12px 10%;
  transition: 0.5s ease-in-out;
}

.logo {
  letter-spacing: 1px;
  font-size: 26px;
  font-weight: 700;
  z-index: 1000;
}
.logo span {
  color: var(--hover-color);
  font-size: 35px;
}

.navlist {
  display: flex;
  justify-content: flex-start;
}
a {
  letter-spacing: 1px;
  color: var(--text-color);
  font-weight: 500;
  padding: 10px 20px;
  animation: slideAnimation 1s ease forwards;
  animation-delay: calc(.3s * var(--i));
  opacity: 0;
  display: inline-block;
}

a:hover {
  color: var(--hover-color);
  text-shadow: var(--font-neon-text-shadow);
}
.navlist a.active {
  color: var(--hover-color);
}


.iconsBox {
  cursor: pointer;
  background-color: var(--hover-color);
  padding: 5px;
  border-radius: 3px;
  color: var(--second-bg-color);
  display: none;
  z-index: 1000;
}

@keyframes slideAnimation {

  0% {
    opacity: 0;
    transform:translateY(100px);
  }
  100%{
    opacity: 1;
    transform:translateY(0);
  }
  
}

/* .mobileIcon{
  display: flex;
 position: absolute;
 top:100px;
 right: 1px;
 width:100%;
 height: 100vh;
 text-align: center;
 background-color: var(--second-bg-color);
 z-index: 1;


 
} */
/* .navlistMobile{
 display:flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 gap: 20px;
 width: 100%;
 font-size: 20px;
 z-index: 1;


} */

@media screen and (max-width: 950px) {
  .navlist {
    display: flex;
    position: absolute;
    text-align: center;
    top: -800%;
    right: 0;
    left: 0;
    flex-direction: column;
    background-color: var(--bg-color);
    transition: all 0.6s ease;
    height: 100vh;
    gap: 4%;
    padding: 5%;
  }

  .open {
    top: 100%;
  }

  .navlist li a {
    font-size: 1.3rem;
  }

  .iconsBox {
    display: flex;
  }
}
