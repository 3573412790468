.footer{
 display: flex;
 justify-content: center;
 align-items: center;
 text-align: center;
}

.footer p{
  font-size: 0.8rem;
  letter-spacing: 2px;
  opacity: .8;

}