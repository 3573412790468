
.education{
 scroll-margin: 6rem;
 width:100%;



}
.education-main{
   display: flex;


}
.circle-line {
  grid-area: 1 / 1 / 6 / 2;
  color: var(--hover-color);
  flex-basis: 20%;
}
.circle {
  width: 15px;
  height: 15px;
  background-color: var(--hover-color);
  border-radius: 50%;
  border: 2px solid;
}

.line {
  border-left: 3px solid var(--hover-color);
  height: 480px;
  margin-left: 6px;
}



.education-container {
  display: flex;
  flex-direction: column;
  flex-basis: 80%;
}

.education1, .education2{
 line-height: 2;
}

.education-container h4{
 font-size: 1.2rem;
 color: var(--hover-color);
 font-weight: 700;
}

.education-container h5{
 font-size: 0.8rem;
 font-weight: 400;
}

.education-container p{
 font-size: 1rem;
 margin: 20px 0;
}

@media screen and (max-width:1280px) {
 .line{
  height:550px;
 }
 
}
@media screen and (max-width:1024px) {
 .line{
  height: 600px;
 }
 
}
@media screen and (max-width:912px) {
 .line{
  height: 650px;
 }
 .education-container p{
  font-size: 1rem;
 }
 
}
@media screen and (max-width:820px) {
 .section__padding{
  padding:2rem;
 }
 .circle-line{
  display: none;
 }
 .education-container{
  flex-basis: 100%;

 }
 .education-container p{
  font-size: .7rem;
 }
 
}

