.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
}
.header-info{
 display: flex;
 flex-direction: column;
 gap:2em;
 width:100%;
 margin-top: 4rem;
}

.header h1{
 font-weight: 700;
 font-size: 35px;
}
.change-text{
 font-size: 1.2rem;
 font-weight: 500;
}

.change-text h3{
 display: inline-flex;
 vertical-align: top;
 margin: 0;
}

.text {
 margin-left: 10px;
 color: var(--hover-color);
 letter-spacing: 1px;
}

.describe{
 width: 100%;
}

.describe p{
  line-height: 2;
  letter-spacing: 1px;
  margin: 10px 0;
  font-size: 0.8rem;


}

.email-info{
 width: 450px;
 display: flex;
 justify-content: flex-start;
 align-items: center;
}



.social-icons{
 margin-top: 8rem;
 margin-bottom: 2rem;
 display: flex;
 gap: 1rem;
 /* justify-content: space-around; */
 align-items: center;
 width: 220px;
 height: 40px;
 text-align: center;
   z-index: 0;

 
}

.social-icons a{
 display: inline-flex;
 width: 50px;
 height: 100%;
 justify-content: center;
 align-items: center;
 color:var(--hover-color);
 background-color: var(--bg-color);
 border: 2px solid var(--hover-color);
 transition: .6s;
 box-shadow: 0 0 0.3rem #12f7ff;
 border-radius: 5px;
 position: relative;
 z-index: 1;
 overflow: hidden;
}

.social-icons a:hover{
 color: var(--bg-color)
}

.social-icons a::before{
 content: "";
 position: absolute;
 left: 0;
 top: 0;
 width:0;
 height: 100%;
 background: var(--hover-color);
 transition: .6s;
 z-index: -1
}

.social-icons a:hover::before{
 width: 100%;

} 



.header-image{
  position: relative;
}

.image-box{
  text-align: center;
  width: 600px;

  
}

.image-box img{
  width: 100%;
  height: auto;
 
}

.liquid-shape{
  position: absolute;
  max-width: 100%;
  width: 100%;
  height: 100%;
  z-index: -1;
  top:22%
}

.liquid-shape:nth-child(2){
  filter: blur(50px);
}

@media screen and (max-width:1024px) {
 .text,  .change-text h3{
  font-size: 0.9rem;
  
 }
 .section__padding{
  padding: 2rem;
 }
 .btn-box{
   margin: auto;

 }
 .header{
  flex-direction: column-reverse;
 }

 .image-box{
  width:450px;
  margin-top: 4rem;
 }
 .liquid-shape{
  top:20%;
 }
 
 
}

@media screen and (max-width:280px){
.image-box{
  width:250px;
}

.header h1{
  font-size: 20px;
}
.email-info span{
 font-size: 0.7rem;
}

  
}







