.contact{
 background: var(--second-bg-color);
 width:100%;
 scroll-margin: 6rem;

}

.contact form{
 text-align:center ;
 max-width: 50rem;
 margin: 1rem auto;
 margin-bottom: 3rem;

}

.contact form input,
.contact form textarea{
 width: 100%;
 color: var(--text-color);
 background: var(--bg-color);
 margin-bottom: .8rem;
 border: none;
 border-radius: 5px;
 padding: .7rem;
}

textarea{
 resize: none;
}

.form-container{
 text-align: center;

}

.form-container span{
 color: rgb(238, 48, 48);
 font-size: 1.2rem;
 font-weight: 500;
}