@import url("https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,400;1,600&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* , ::before, ::after{
  list-style: none;
  text-decoration: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;

}
html, body , #root{
  max-width: 100vw;
  overflow-x: hidden;

  scroll-behavior: smooth;


}







:root {
  --text-color: #f5f5f5;
  --hover-color: #ffb43a;
  --bg-color: #250821;
  --second-bg-color: #292e33;
  --big-font: 2.5rem;
  --normal-font: 2rem;
  --neon-box-shadow: 0 0 0.7rem #ffb43a
  --h2-font: 3rem;
  --font-neon-text-shadow: 0 0 10px rgb(255, 180, 58, 0.6),
    0 0 20px rgb(255, 180, 58, 0.6), 0 0 30px rgb(255, 180, 58, 0.6),
    0 0 40px rgb(255, 180, 58, 0.6), 0 0 70px rgb(255, 180, 58, 0.6),
    0 0 80px rgb(255, 180, 58, 0.6), 0 0 100px rgb(255, 180, 58, 0.6),
    0 0 150px rgb(255, 180, 58, 0.6);
}

/* change the scroll bar style */

::-webkit-scrollbar {
  height: 0;
  width: 0.5rem;
}
::-webkit-scrollbar-track {
  background-color: var(--second-bg-color);
}
::-webkit-scrollbar-thumb {
  background-color: var(--hover-color);
  border-radius: 5rem;
}
body {
  font-family: "Poppins", sans-serif;
  background-color: var(--bg-color);
  color: var(--text-color);
}

/* scroll website animation */
.scroll-scale {
  opacity: 0;
  transform: scale(0.9);
  transition: all 2s;
}

.scroll-bottom {
  opacity: 0;
  transform: translateY(300px);
  transition: 3s;
}
.scroll-top {
  opacity: 0;
  transform: translateY(-300px);
  transition: 3s;
}
.show-items {
  opacity: 1;
  transform: translateX(0);
}

