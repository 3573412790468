.skills{
 background-color: var(--second-bg-color);
 scroll-margin: 6rem;
 width: 100%;

}

.main-skill{
 width: 100%;
 display: grid;
 grid-template-columns: repeat(2, 1fr);
 /* grid-row-gap: 30px;
 grid-column-gap: 50px; */


}

.skill-bar{
 margin-bottom: 2.3rem;
}

.main-skill h3{
 margin-bottom: 2rem;
 font-size: var(--normal-font);
 text-align: center;
}


.info{
 width: 190%;
 display: flex;
 justify-content: space-between;
 align-items: center;
 
 padding: 0 10px;
}

.technical .info p{
 font-size: 0.7rem;
 font-weight: 400;
}

.technical .bar{
 width: 100%;
 height: 10px;
 background: var(--bg-color);
 border-radius: 25px;
 margin-top: 10px;
 position: relative;
}

.bar span{
 width: 50%;
 height: 100%;
 position: absolute;
 left:0;
 background-color: var(--hover-color);
 border-radius: 25px;
 box-shadow: var(--neon-box-shadow);
}

.bar span.html{
 width: 85%;
 animation: html 2s;
}
.bar span.figma{
 width: 70%;
  animation: figma 3s;

}
.bar span.css{
 width: 85%;
  animation: css 4s;

}
.bar span.javascript{
 width: 80%;
  animation: javascript 5s;

}
.bar span.react{
 width: 80%;
  animation: react 6s;

}
.bar span.nextJs{
 width: 80%;
  animation: nextJs 7s;

}
.bar span.nodeJs{
 width: 80%;
  animation: nextJs 8s;

}
.bar span.java{
 width: 70%;
  animation: nextJs 9s;

}
.bar span.python{
 width: 80%;
  animation: nextJs 9s;

}
.bar span.sql{
 width: 75%;
  animation: nextJs 10s;

}
.bar span.noSql{
 width: 75%;
  animation: nextJs 11s;

}



@keyframes html{
 0%{
  width: 0%;
 }
 100%{
  width: 75%
 }
}
@keyframes figma{
 0%{
  width: 0%;
 }
 100%{
  width: 70%
 }
}
@keyframes css{
 0%{
  width: 0%;
 }
 100%{
  width: 72%
 }
}
@keyframes javascript{
 0%{
  width: 0%;
 }
 100%{
  width: 70%
 }
}
@keyframes react{
 0%{
  width: 0%;
 }
 100%{
  width: 70%
 }
}
@keyframes nextJs{
 0%{
  width: 0%;
 }
 100%{
  width: 72%
 }
}
@keyframes nodeJs{
 0%{
  width: 0%;
 }
 100%{
  width: 70%
 }
}
@keyframes java{
 0%{
  width: 0%;
 }
 100%{
  width: 65%
 }
}
@keyframes sql{
 0%{
  width: 0%;
 }
 100%{
  width: 60%
 }
}
@keyframes noSql{
 0%{
  width: 0%;
 }
 100%{
  width: 60%
 }
}

/* professional */

.professional{
 display: grid;
 grid-template-columns: 1fr 1fr;
}

.box{
 margin: 10px 0;
 flex: 1 1 15rem;
 position: relative;
}

.box .text-container{
 text-align: center;
 color: #fff;
 font-size: 1rem;
}

.box .text-container big{
font: 400;
letter-spacing: 1px;
position: absolute;
top: 40%;
left: 50%;
transform: translate(-50%, -50%);

}

.box .text-container small{
 display: block;
 font-weight: 600;
}
.circle-percentage{
 width: 100%;
 height: 120px;
 display: flex;
 justify-content: center;
 align-items: center;
}

.circle-percentage .points{
 width: 2px;
 height: 10px;
 background-color: var(--bg-color);
 position: absolute;
 border-radius: 3px;
 transform: rotate(calc(var(--i)*var(--rot))) translateY(-45px);
 
}

.points.marked{
 animation: glow 0.04s linear forwards;
 animation-delay: calc(var(--i)*0.05s);
}

@keyframes glow {
 0%{
  background: var(--bg-color);
  box-shadow: none;
 }

 100%{
   background: var(--hover-color);
  box-shadow: var(--neon-box-shadow);
 }
 
}

@media screen and (max-width:950px){
 .main-skill{
   grid-template-columns: repeat(1, 1fr)
 }

 .main-skill h3{
  font-size: 1.2rem;
 }
 
}

