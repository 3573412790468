.section__padding {
  padding: 2rem 4rem;
}

.app{
  overflow: hidden;
  max-width: 100%;
}
.lineHeader{
  width:80px;
  height: 5px;
  background: var(--hover-color);
  margin: 1rem 0 3rem;
  border-radius: 5px;
}

h2{
  font-size: var(--normal-font);
  letter-spacing: 1px;
  font-weight: 700;

}

p{
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 1px;
  width: 100%;
  
}

.btn-box{
 width:180px;
 height: 45px;
   z-index: 0;


}

.btn-box .btn{
 display: inline-flex;
 justify-content: center;
 align-items: center;
 margin: 20px 0;
 width: 100%;
 height: 100%;
 background-color: var(--hover-color);
 font-size: 1rem;
 letter-spacing: 1px;
 font-weight: 600;
 transition: .6s;
 box-shadow: var(--neon-box-shadow);
 color: var(--bg-color);
 border-radius: 5px;
 position: relative;
 z-index: 1;
 border: 2px solid var(--hover-color);
 cursor: pointer;
}

.btn:hover{
 color:var(--hover-color)
}

.btn::before{
 content:"";
 position:absolute;
 top:0;
 left:0;
 background-color: var(--bg-color);
 width:0;
 height: 100%;
 z-index: -1;
 transition: 0.4s;

}

.btn:hover::before{
 width:100%
}

@media screen and (max-width:480px) {
  h2{
    font-size: 1.2rem;
  }
  .section__padding{
  padding:2rem;
 }
 
  
}

