#about{
  width: 100%;
    background: var(--second-bg-color);
    scroll-margin: 6rem;

}
.about {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;
}

.about span {
  color: var(--hover-color);
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 2px;
}

.about-image {
  position: relative;
  width: 400px;
  max-height: 450px;
  background-color: var(--hover-color);
  border-radius: 70% 60% 50% 30% / 60% 30% 40% 60%;
  box-shadow: 0 1rem 4rem var(--hover-color);
  border: 4px solid #fff;
}

.about-image img {
  width: 100%;
  height: 100%;
}

.desc {
  flex-basis: 60%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.info-about1,
.info-about2 {
  background: var(--bg-color);
  font-size: 0.5rem;
  position: absolute;
  padding: 10px;
  width: 100px;
  height: 90px;
  border-radius: 69% 31% 66% 34% / 21% 30% 70% 79%;
  display: block;
  box-shadow: var(--neon-box-shadow);
  border: 1px solid var(--hover-color);
  outline: 2px solid var(--bg-color);
  z-index: index 100;
  animation: morph 6s linear infinite;
}
.info-about1 p,
.info-about2 p {
  font-size: 0.6rem;
  font-weight: 400;
  margin-top: 5px;
}

.info-about1{
 left: -10%;
 top: 20%;
text-align: center;
}

.info-about2{
 left: 90%;
 bottom: 20%;
 text-align: center;
}

@keyframes morph {
  0%,
  100% {
    border-radius: 40% 60% 70% 30% / 40% 40% 60% 50%;
  }
  30% {
    border-radius: 70% 30% 50% 50% / 30% 30% 70% 70%;
  }

  60% {
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
  }

  80% {
    border-radius: 69% 31% 66% 34% / 21% 30% 70% 79%;
  }
}

@media screen and (max-width: 1280px) {
  .about {
    flex-direction: column;
    padding: 0.5rem;
  }

  .about-image{
   margin-bottom: 4rem;
  }
 
 
}

@media screen and (max-width: 650px) {
  .desc {
    width: 100%;
    margin-top: 2rem;
    
  }
  .desc p{
       font-size: 0.7rem;

  }
  .section__padding {
    padding: 2rem;
  }
  .info-about1, .info-about2{
  width: 70px;
  height: 70px;
 }

 .info-about1 span, .info-about2 span{
  font-size: .5rem;
 }
 .info-about1 p, .info-about2 p{
  font-size: .5rem;
 }
  
  .about-image{
   width: 180px;
   margin-bottom: 2rem;
  }
}

